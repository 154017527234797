import { Component } from '@angular/core';
import firebase from 'firebase/compat/app';
import { isEligibleForFreeEvent } from 'libs/common/utils/CouponUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { DbCollections } from 'src/app/interfaces/Collections.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { CouponType } from 'src/app/interfaces/Coupon.enum';
import { Coupon } from 'src/app/interfaces/Coupon.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { CouponService } from 'src/app/services/coupon.service';
import { DatabaseService } from 'src/app/services/database.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-coupon-generator',
  templateUrl: './coupon-generator.component.html',
  styleUrls: ['./coupon-generator.component.scss'],
})
export class CouponGeneratorComponent {
  public couponsInput: string = '';
  public discountType = Object.values(CouponType);
  public couponType = CouponType;
  public couponData: Coupon = {
    discountType: this.discountType[0],
    isOnlyForNewCustomers: false,
    expires: firebase.firestore.Timestamp.fromDate(new Date()),
    onlyUseOnce: false,
  };
  public readonly ButtonType = ButtonType;
  public readonly buttonActionType = ButtonActionType;
  public readonly isEligibleForFreeEvent = isEligibleForFreeEvent;
  public readonly TitleType = TitleType;

  constructor(
    private db: DatabaseService,
    private popupDialog: PopupDialogService,
    private spinner: NgxSpinnerService,
    private coupon: CouponService
  ) {}

  public setDate(date: Date): void {
    this.couponData.expires = firebase.firestore.Timestamp.fromDate(date);
  }

  public async generateQrCode(): Promise<void> {
    this.spinner.show();
    let noSpaces = this.couponsInput.replace(/\s+/g, '');
    let coupons = noSpaces.split(',');

    if (isEligibleForFreeEvent(this.couponData.discountType)) {
      for (let i = 0; i < coupons.length; i++) {
        if (coupons[i] === '') {
          this.popupDialog.openMessageModalPopup({
            msg: 'coupon_generator.popup.error.incorrect_coupons',
            success: false,
          });
          this.spinner.hide();
          return;
        }
      }
      if (!this.couponData.freeEvents || this.couponData.freeEvents === 0) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.free_events',
          success: false,
        });
        this.spinner.hide();
        return;
      }
      delete this.couponData.discount;
      delete this.couponData.stripePromotionId;
    } else {
      if (!('stripePromotionId' in this.couponData) || !this.couponData.stripePromotionId) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.failed.missing_stripe_promotion_id',
          success: false,
        });
        this.spinner.hide();
        return;
      } else if (!coupons.length || coupons[0] === '') {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.coupon_code',
          success: false,
        });
        this.spinner.hide();
        return;
      } else if (coupons.length > 1) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.comma_in_coupon',
          success: false,
        });
        this.spinner.hide();
        return;
      } else if (
        !('discount' in this.couponData) ||
        !this.couponData.discount ||
        this.couponData.discount < 1 ||
        (this.couponData.discountType === this.couponType.PERCENTAGE && this.couponData.discount > 100)
      ) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.discount',
          success: false,
        });
        this.spinner.hide();
        return;
      }

      const res = await firstValueFrom(this.db.getById<Coupon>(DbCollections.COUPONS, coupons[0]));

      if (res) {
        this.popupDialog.openMessageModalPopup({
          msg: 'coupon_generator.popup.error.coupon_already_exists',
          success: false,
        });
        this.spinner.hide();
        return;
      }

      delete this.couponData.freeEvents;
    }

    if (isEligibleForFreeEvent(this.couponData.discountType)) {
      this.spinner.hide();
      this.popupDialog
        .openConfirmationPopup({
          type: ConfirmationPopupType.DEFAULT,
          heading: 'admin_page.coupons.generate_coupons.confirmation_popup.title.generate_coupons',
          description: 'admin_page.coupons.generate_coupons.confirmation_popup.description.generate_coupons',
          buttonText: 'admin_page.coupons.generate_coupons.confirmation_popup.button.generate_coupons',
        })
        .subscribe(res => {
          this.spinner.show();

          if (res) {
            this.coupon.generateCoupons(coupons, this.couponData).finally(() => {
              this.spinner.hide();
            });
          } else {
            this.spinner.hide();
          }
        });
    } else {
      await this.coupon.generateCoupons(coupons, this.couponData);

      this.spinner.hide();
    }
  }

  public getOptionText(type: CouponType): string {
    switch (type) {
      case CouponType.AMOUNT:
        return 'coupon_generator.form.option.amount';
      case CouponType.FREE_EVENT:
        return 'coupon_generator.form.option.free_event';
      case CouponType.PERCENTAGE:
        return 'coupon_generator.form.option.percentage';
      case CouponType.VIP:
        return 'coupon_generator.form.option.vip';
    }
  }
}
