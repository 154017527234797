export enum ErrorMessagesType {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  phoneNumber = 'phoneNumber',
  eventRefId = 'eventRefId',
  couponCode = 'couponCode',
}
