import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { FormInputId } from 'src/app/interfaces/Form.enum';
import { FormCredentials } from 'src/app/interfaces/Form.interface';
import { LoginCredentials } from 'src/app/interfaces/LoginCredentials.interface';
import { AuthPageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { AuthPageLayout } from 'src/app/interfaces/PageLayout.interface';
import { RegistrationCredentials } from 'src/app/interfaces/Registration.interface';
import { PublicUserRoutes } from 'src/app/interfaces/Routes.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CouponService } from 'src/app/services/coupon.service';
import { TrackingService } from 'src/app/services/tracing.service';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent {
  @Input() page?: AuthPageLayout;
  public readonly layoutType = AuthPageLayoutType;
  public readonly titleType = TitleType;
  public readonly descriptionType = DescriptionType;
  public readonly buttonType = ButtonType;
  public filledInputs: FormCredentials = {};

  constructor(
    private auth: AuthenticationService,
    private trackingService: TrackingService,
    private coupon: CouponService,
    private route: ActivatedRoute
  ) {
    const couponCode = this.coupon.checkSavedCoupons();
    if (couponCode) {
      this.filledInputs[FormInputId.COUPON_CODE] = couponCode;
    }

    const regEmail = this.route.snapshot.queryParamMap.get('email');
    if (regEmail) {
      this.filledInputs[FormInputId.EMAIL] = regEmail;
    }
  }

  public authWithGoogle(): void {
    this.trackingService.logEvent('action', { login: 'Google' });
    this.auth.authWithGoogle();
  }

  public authWithMicrosoft(): void {
    this.trackingService.logEvent('action', { login: 'Microsoft' });
    this.auth.authWithMicrosoft();
  }

  public authWithApple(): void {
    this.trackingService.logEvent('action', { login: 'Apple' });
    this.auth.authWithApple();
  }

  public emitFromForm(data: FormCredentials): void {
    switch (this.page.type) {
      case AuthPageLayoutType.LOGIN:
        this.auth.login(data as LoginCredentials);
        break;
      case AuthPageLayoutType.REGISTRATION:
        this.auth.regEmail(data as RegistrationCredentials);
        break;
      case AuthPageLayoutType.PASSWORD_RESET:
        this.auth.passwordReset(data['email'] as string);
        break;
    }
  }

  public getRouterLink(type: string) {
    switch (type) {
      case this.layoutType.LOGIN:
        return PublicUserRoutes.REGISTRATION;
      default:
        return PublicUserRoutes.LOGIN;
    }
  }

  public getLinkText(type: string): string {
    switch (type) {
      case this.layoutType.LOGIN:
        return 'login_page.link.dont_have_account';
      case this.layoutType.PASSWORD_RESET:
        return 'password_reset_page.input.back_to_login';
      default:
        return 'registration_page.link.have_account_already';
    }
  }
}
